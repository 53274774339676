import React from 'react';
import '../Login.scss';
import { MainButton } from '../MainButton';
import logo from '../../../../assets/img/logo/logo1.svg';
import { LoginHeader } from '../LoginHeader/LoginHeader';
import { useAuth0 } from '@auth0/auth0-react';

export const UsernameAndPasswordLogin: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="container">
      <img src={logo} alt="logo" />
      <div className="login-container">
        <LoginHeader title="Login" />
        <div className="button-container">
          <MainButton text="Login" fullWidth onClick={loginWithRedirect} />
        </div>
      </div>
    </div>
  );
};
