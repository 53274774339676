import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <CircularProgress size={14} style={{ color: '#FFFFFF', marginLeft: 5 }} />
    );
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;
