export interface StdTest {
  id: number;
  filePath: string;
  createdAt: string;
  validFrom?: string;
  validTo?: string;
  approvalStatus: StdTestApprovalStatus;
}

export enum StdTestApprovalStatus {
  Pending,
  Approved,
  Rejected,
}

export interface UpdateStdTest {
  id: number;
  validFrom?: string;
  validTo?: string;
  approvalStatus: StdTestApprovalStatus;
}
