import produce from 'immer';
import {
  SetCredentialsAction,
  CredentialsActionType,
} from './credentials.action';
import { Action } from 'redux';
import { CookieMonster } from '../clients/CookieMonster';

export interface CredentialsState {
  token: string;
}

const initCredentialsState = (): CredentialsState => {
  return { token: '' };
};

export const credentialsReducer = (
  state: CredentialsState = initCredentialsState(),
  action: Action<CredentialsActionType>
): CredentialsState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case 'SET_CREDENTIALS':
        const { token } = action as SetCredentialsAction;
        draftState.token = token;
        return draftState;
      case 'REMOVE_CREDENTIALS':
        draftState.token = '';
        return draftState;
      default:
        return state;
    }
  });
};
