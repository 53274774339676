import { Action } from 'redux';
import produce from 'immer';
import {
  CountUserPhotosToApproveAction,
  CountUserVerificationToApprove,
  UsersAssetsNotificationTypes,
} from './usersAssetsToBeApproved.action';

export type UsersAssetsNotificationState = {
  photosToReview: number;
  videosToReview: number;
  selfiesToRevew: number;
  stdTests: number;
};

const initState: UsersAssetsNotificationState = {
  photosToReview: 0,
  videosToReview: 0,
  selfiesToRevew: 0,
  stdTests: 0,
};

export const usersAssetsToBeApprovedReducer = (
  state: UsersAssetsNotificationState = initState,
  action: Action<UsersAssetsNotificationTypes>
): UsersAssetsNotificationState => {
  return produce(state, (draftState: any) => {
    switch (action.type) {
      case 'GET_USER_PHOTOS_TO_APPROVE_COUNT':
        draftState = {
          ...draftState,
          photosToReview: (action as CountUserPhotosToApproveAction).value,
        };
        return draftState;
      case 'GET_USER_VIDEOS_TO_APPROVE_COUNT':
        return {
          ...draftState,
          videosToReview: (action as CountUserPhotosToApproveAction).value,
        };
      case 'GET_USER_VERIFICATION_ITEMS_TO_APPROVE':
        return {
          ...draftState,
          selfiesToRevew: (action as CountUserVerificationToApprove).value,
        };
      case 'COUNT_USER_PHOTOS_TO_APPROVE_FAIL':
        return initState;
      case 'COUNT_USER_VIDEOS_TO_APPROVE_FAIL':
        return initState;
      default:
        return state;
    }
  });
};
