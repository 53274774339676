import ReactDOM from 'react-dom';
import { App } from './App';
import React from 'react';
import { Provider } from 'react-redux';
import { getStore } from './rootReducer';
import { Auth0Provider } from '@auth0/auth0-react';
export const store = getStore();

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={window.app_config.auth0_domain}
      clientId={window.app_config.auth0_clientId}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid profile email offline_access',
        audience: window.app_config.auth0_audience,
        connection: 'Admin-Authentication',
      }}
    >
      <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById('root')
);
