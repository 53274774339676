import React, {useEffect, useState} from 'react';
import {Header} from '../../Elements/Header/Header';
import {useSelector} from 'react-redux';
import {AppState} from '../../../rootReducer';
import {PaginatedResultModel} from '../../../shared/paginated-result.model';
import {useConfirm} from 'material-ui-confirm';
import './Configuration.scss';
import {makeStyles} from '@material-ui/core/styles';
import {Link, useHistory} from 'react-router-dom';
import {AdminConfigService} from "../../../service/AdminConfigService";
import {ConfigurationModel} from "./configuration.model";
import {InputText} from "primereact/inputtext";

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

export const Configuration: React.FC = () => {
  const [page, setPage] = useState(1);
  const [configList, setConfigList] = useState<
    PaginatedResultModel<ConfigurationModel>
  >({ results: [], resultCount: 0 });


  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const history = useHistory();
  const classes = useStyles();
  const pageSize = 10;

  const getConfigList = async () => {
    const data = await AdminConfigService.getConfigs(
      token,
    );
    setConfigList({ results: data, resultCount: data.length });
  };

  const updateConfig = async (property: string, value: string) => {
    var result = await AdminConfigService.updateConfig(
        token, property, value
    );
    if (result) {
      var item = configList.results.find(cfg => cfg.configProperty == property)
      if (item) {
        item.configValue = value;
      }
      setConfigList({ results: configList.results, resultCount: configList.resultCount });
    }
  };

  useEffect(() => {
    (async () => {
      await getConfigList();
    })();
  }, [page]);

  const uniqueCategories = Array.from(new Set(configList?.results.map(item => item.category)));

  return (
    <div>
      <Header title={'Configuration'} />

      <div className="logsContainer">


        {uniqueCategories.map(category => (
            <div key={category}>
              <h2 className={'categoryHeader'}>
                <div className={'categoryHeader-content'}>
                  <span>{category}</span>
                  <span>Value</span>
                </div>
              </h2>
              <ul>
                {configList?.results
                    .filter(item => item.category === category)
                    .map((item, index) => (
                        <div key={index} className={'configItem'}>
                          <div className={'configItem-details'}>
                            <span>{item.configProperty}</span>
                            <span> {item.configValue}</span>
                          </div>
                          <div className={'configItem-actions'}>
                            <ConfigActions
                                value={item.configValue}
                                config={item}
                                updateConfig={updateConfig}
                            />
                          </div>
                        </div>
                    ))}
              </ul>
            </div>
        ))}


      </div>
    </div>
  );
};

const ConfigActions = ({
  value,
  config, updateConfig,
}: {
  value: string;
  config: ConfigurationModel;
  updateConfig: (property: string, value: string) => void;
}) => {
  const confirm = useConfirm();
  const [open, setOpen] = React.useState(false);
  const switchOpen = () => {
    setOpen(!open);
  };
  const [editedValue, setEditedValue] = React.useState(value);
  const token = useSelector(
    (state: AppState) => state.data.user.credentials.token
  );

  const confirmTransaction = () => {
    confirm({
      title: 'Confirm change',
      description: `Are you sure you want to edit the value?`,
    })
      .then(async () => {
        updateConfig(config.configProperty, editedValue);
        setOpen(false);
      })
      .catch(() => setOpen(false));
  };

  return (
    <div>
        <div className="dropdown">
          <button className="cursor-pointer" onClick={switchOpen}>
            <b>{!open ? <>...</> : <>x</>}</b>
          </button>
          {open ? (
              <ul className="menu">

                <li className="menu-item config">
                  <InputText
                      id="countryName"
                      name="countryName"
                      className="input"
                      value={editedValue}
                      onChange={(e) =>
                          setEditedValue(e.target.value)
                      }
                  />
                  <button className="success" onClick={confirmTransaction}>
                    Confirm
                  </button>


                </li>
              </ul>
          ) : null}
        </div>

    </div>
  );
};
