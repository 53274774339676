import React from 'react';
import {AppState} from '../../../../../rootReducer';
import {connect} from 'react-redux';
import {AdminUserService} from '../../../../../service/AdminUserService';
import {StdTest, StdTestApprovalStatus, UpdateStdTest} from './std-test';
import './StdTests.scss'
import imgDoc from '../../../../../assets/img/pic/image-doc.png';
import ImgCheck from '../../../../../assets/img/icon/icon-check.svg'
import Close from '../../../../../assets/img/icon/close.svg'
import imgDocModal from '../../../../../assets/img/pic/document-picture-big.png';
import {Calendar} from 'primereact/calendar';


type StdTestsState = {
  token: string;
  stdTests: StdTest[];
  openIndex: number | null,
  selectedItems: StdTest,
  showModal: boolean | null,
  date: any
};

type StdTestsProps = {
  userId: string | number;
};

class StdTests extends React.Component<StdTestsProps, StdTestsState> {
  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      stdTests: [],
      openIndex: 0,
      showModal: false,
      selectedItems: {
        id: 0,
        filePath: "",
        createdAt: "",
        approvalStatus: 0,
      },
      date: null
    };
  }

  componentDidMount() {
    this.loadStdTests();
  }

  loadStdTests() {
    AdminUserService.getStdTests(+this.props.userId, this.state.token).then(
      (data) => {
        this.setState({ ...this.state, stdTests: data });
      }
    );
  }

  toggleAccordion = (index:number) => {
    this.setState((prevState) => ({ openIndex: prevState.openIndex === index ? null : index }));
  };

  selectItem = (item:StdTest) => {
      if(item.validTo && item.validTo && new Date(item.validTo)  >  new Date() ||  item.validFrom && new Date(new Date(item.validFrom).setMonth(new Date(item.validFrom).getMonth() + 3))  > new Date() || item.approvalStatus === StdTestApprovalStatus.Pending || item.approvalStatus === StdTestApprovalStatus.Rejected ) {
        this.setState({ ...this.state, selectedItems: item , showModal: true });
      }

  }

  closeModal = () => {
    this.setState({ ...this.state, selectedItems: {
        id: 0,
        filePath: "",
        createdAt: "",
        approvalStatus: 0,
      } , showModal: false });
  }

  selectDate = (value) => {
    this.setState({ ...this.state, date: value });
  }


  updateStdTest(testData: UpdateStdTest) {
    AdminUserService.updateStdTest(
      +this.props.userId,
      this.state.token,
      testData
    ).then((data) => {
      if (data) {
        this.loadStdTests();
        this.closeModal()
        if(testData.approvalStatus === StdTestApprovalStatus.Approved ) {
          this.setState({ ...this.state, openIndex: 1 });
        } else {
          this.setState({ ...this.state, openIndex: 2 });
        }
      }
    });
  }

  formatDate = (dateString:string) => {
    const date = new Date(dateString.replace(/(\d{4})(\d{2})(\d{2})T(.*)/, "$1-$2-$3T$4"));
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  render() {
    return (
      <div className="userSTD">
        <div className={''}>
          <p className='title'>STD tests</p>
          <p className='description'>Manage user STD tests</p>
        </div>


        <div className="w-64 border rounded-lg shadow-md">
          <div className="border-b">
            <button
                onClick={() => this.toggleAccordion(0)}
                className={`w-full flex justify-between items-center accordion_header ${this.state.openIndex === 0 ? 'active': ''}`}
            >
              Pending approval

              <div className={`arrow-down  ${this.state.openIndex === 0 ? 'active': ''}`}></div>
            </button>
            {this.state.openIndex === 0 && (
                <div className="p-4">
                  <div className={'documentsWrapper'}>
                    {this.state.stdTests.filter((item:StdTest)=> (item.approvalStatus === StdTestApprovalStatus.Pending)).map((item:StdTest) => (
                          <div className={'documentItem'}  key={item.id} onClick={()=> this.selectItem(item)}>
                            <img src={imgDoc} />
                            {this.state.selectedItems?.id === item.id && <ImgCheck className={'checkImage'}/>}
                          </div>
                    ))}

                  </div>
                  {this.state.stdTests.filter((item:StdTest)=> (item.approvalStatus === StdTestApprovalStatus.Pending)).length === 0 && <p className={'emptyItems'}>No items to review</p>}

                </div>
            )}
          </div>

          <div className="border-b">
            <button
                onClick={() => this.toggleAccordion(1)}
                className={`w-full flex justify-between items-center accordion_header ${this.state.openIndex === 1 ? 'active': ''}`}
            >
              Approved

              <div className={`arrow-down  ${this.state.openIndex === 1 ? 'active': ''}`}></div>
            </button>
            {this.state.openIndex === 1 && (
                <div className="p-4">
                  <div className={'documentsWrapper'}>
                    {this.state.stdTests.filter((item:StdTest)=> (item.approvalStatus === StdTestApprovalStatus.Approved)).map((item:StdTest) => (

                          <div className={'documentItem'} key={item.id} onClick={()=> this.selectItem(item)}>
                            <img src={imgDoc} />
                            {this.state.selectedItems?.id === item.id && <ImgCheck className={'checkImage'}/>}
                            { item.validTo ? (
                                <>
                                  { item.validTo && new Date(item.validTo)  <  new Date()  ?  <div className={'status expired'}>Expire ({this.formatDate(item.validTo)})</div> :  <div className={'status valid'}>Valid ({this.formatDate(item.validTo)})</div>  }
                                </>
                            ) : (<>
                                  {item.validFrom && new Date(new Date(item.validFrom).setMonth(new Date(item.validFrom).getMonth() + 3))  < new Date() ? <div className={'status expired'}>Expired ({this.formatDate( new Date(new Date(item.validFrom).setMonth(new Date(item.validFrom).getMonth() + 3)).toISOString())})</div> :  <div className={'status valid'}>Valid ( {item.validFrom ? (<>{this.formatDate(new Date(new Date(item.validFrom).setMonth(new Date(item.validFrom).getMonth() + 3)).toISOString())}</>): null} )</div> }
                                </>)}


                          </div>

                    ))}
                  </div>
                  {this.state.stdTests.filter((item:StdTest)=> (item.approvalStatus === StdTestApprovalStatus.Approved)).length === 0 && <p className={'emptyItems'}>No items to review</p>}

                </div>
            )}
          </div>

          <div className="border-b">
            <button
                onClick={() => this.toggleAccordion(2)}
                className={`w-full flex justify-between items-center accordion_header ${this.state.openIndex === 2 ? 'active': ''}`}
            >
              Rejected

              <div className={`arrow-down  ${this.state.openIndex === 2 ? 'active': ''}`}></div>
            </button>
            {this.state.openIndex === 2 && (
                <div className="p-4 ">
                  <div className={'documentsWrapper'}>
                    {this.state.stdTests.filter((item:StdTest)=> (item.approvalStatus === StdTestApprovalStatus.Rejected)).map((item:StdTest) => (
                          <div className={'documentItem'}  key={item.id}  onClick={()=> this.selectItem(item)}>
                            <img src={imgDoc} />
                            {this.state.selectedItems?.id === item.id && <ImgCheck className={'checkImage'}/>}
                            <div className={'status rejected'}>Rejected</div>
                          </div>
                    ))}

                  </div>
                  {this.state.stdTests.filter((item:StdTest)=> (item.approvalStatus === StdTestApprovalStatus.Rejected)).length === 0 && <p className={'emptyItems'}>No rejected documents</p>}

                </div>
            )}
          </div>
        </div>
        {this.state.selectedItems &&  <div className={`customModal  ${this.state.showModal ? 'active': ''}`}>
          <div className="modalBg" onClick={ ()=> this.closeModal() }></div>
          <div className={'customModalContent'}>
            <div className={'modalHeader'}>
              <span>Manage STD test</span>
              <span className={'close'} onClick={ ()=> this.closeModal() }>
                <Close/>
              </span>
            </div>

            <a href={this.state.selectedItems?.filePath} target={'_blank'} className={'documentPreview'}>
              <div >
                <img src={imgDocModal} alt="document" className={'documentImage'}/>
                <span className={'documentView'}>View document</span>
              </div>
            </a>

            <p className={'text-center'}>Please check the document and enter the date of the test.</p>

            <div className={'dates'}>
              <div className={'dates-item'}>
                <span className={'customLabel'}> Test Date</span>

                <Calendar value={this.state.selectedItems?.validFrom ? new Date(this.state.selectedItems.validFrom) : this.state.date} onChange={(e) => this.selectDate(e.value)} dateFormat="dd/mm/yy"/>
              </div>
              <div className={'dates-item'}>
                <span  className={'customLabel'}>Valid until (auto-calculated + 3 months)</span>
                <Calendar disabled value={this.state.selectedItems?.validTo ? new Date(this.state.selectedItems.validTo) :  this.state.date ? new Date(new Date(this.state.date).setMonth(new Date(this.state.date).getMonth() + 3))  : null} dateFormat="dd/mm/yy"/>
              </div>
            </div>
            <div className="actions">
              {this.state.selectedItems.approvalStatus !== StdTestApprovalStatus.Rejected &&  <button type={'button'} className={'button reject'}  onClick={()=> this.updateStdTest({id: this.state.selectedItems.id, approvalStatus: 2 })}>
                Reject
              </button> }

              {(this.state.selectedItems.approvalStatus === StdTestApprovalStatus.Pending || this.state.selectedItems.approvalStatus === StdTestApprovalStatus.Rejected) && <button type={'button'} className={'button accept'} onClick={()=> this.updateStdTest({id: this.state.selectedItems.id, approvalStatus: 1, validFrom: this.state.date.toISOString(), validTo: this.state.date ? new Date(new Date(this.state.date).setMonth(new Date(this.state.date).getMonth() + 3)).toISOString() : undefined  })} disabled={!this.state.date}>
                Approve
              </button> }

            </div>
          </div>
        </div>}

      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  token: state.data.user.credentials.token,
});

export default connect(mapStateToProps)(StdTests);
