import React from 'react';
import './ProfileMenu.scss';
import IconUsers from '../../../../assets/img/icon/users.svg';
import NotificationIcon from '../../../../assets/img/icon/notification.svg';
import SettingsIcon from '../../../../assets/img/icon/settings.svg';
import LogoutIcon from '../../../../assets/img/icon/logout.svg';
import { useAuth0 } from '@auth0/auth0-react';

export const ProfileMenu: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <>
      <div className="profileMenuContainer">
        <div className="notificationItem">
          <IconUsers className="headerMenuIcon" />
          Profile
        </div>
        <div className="notificationItem">
          <NotificationIcon className="headerMenuIcon" />
          Notifications
        </div>
        <div className="notificationItem">
          <SettingsIcon className="headerMenuIcon" />
          Settings
        </div>

        <div
          className="logoutItem"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <LogoutIcon className="logoutIcon" style={{ fill: '#FF3A3A' }} />
          Logout
        </div>
      </div>
    </>
  );
};
