export interface TransactionModel {
  id: number;
  amount: number;
  bookingId?: number;
  deducted: boolean;
  dateCreated: string;
  userId: number;
  bexioInvoiceId?: number;
  invoiceDeadline?: string;
  user: TransactionUserModel;
  transactionStatus: TransactionStatus;
  transactionType: TransactionType;
  paymentMethod?: PaymentMethod;
  payoutType: PayoutType;
  invoiceStatus?: InvoiceStatus;
}

export interface TransactionUserModel {
  avatar?: string;
  displayName?: string;
  id: number;
  role: number;
}

export enum TransactionType {
  Welcome = 'Welcome',
  Support = 'Support',
  TopUp = 'TopUp',
  ServiceFee = 'ServiceFee',
  ServiceFeeRefund = 'ServiceFeeRefund',
  CancellationCompensation = 'CancellationCompensation',
  Referral = 'Referral',
  Payout = 'Payout',
  BalanceSettlement = 'BalanceSettlement',
  BookingPayment = 'BookingPayment',
  BookingCancellation = 'BookingCancellation',
}

export enum PaymentMethod {
  BankTransfer = 'BankTransfer',
  PaymentSlip = 'PaymentSlip',
  SwissPost = 'SwissPost',
  GingrCard = 'GingrCard',
  BankAccount = 'BankAccount',
  GingrOffice = 'GingrOffice',
  Invoice = 'Invoice',
  CreditInvoice = 'CreditInvoice',
  Wallet = 'Wallet',
  Card = 'Card',
}

export enum PayoutType {
  SwissPost = 'SwissPost',
  GingrOffice = 'GingrOffice',
  GingrCard = 'GingrCard',
  BankAccount = 'BankAccount',
}

export enum TransactionStatus {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Rejected = 'Rejected',
  PendingEscort = 'PendingEscort',
}

export enum InvoiceStatus {
  Draft = 7,
  Pending = 8,
  Paid = 9,
  Partial = 16,
  Canceled = 19,
  Unpaid = 31,
}

export enum InvoiceStatusValues {
  Draft = 'Draft',
  Pending = 'Outstanding',
  Paid = 'Paid',
  Partial = 'Partial',
  Canceled = 'Canceled',
  Unpaid = 'Overdue',
}
